import React from "react";
import "../../Screens/DeleteAccount/DeleteAccount.css"

const DeleteAccountEmailField = (props) => {
    const { email, handleEmailInput, showOtpField } = props
    return (
        <>
            <label className="main-form__label" htmlFor="email">Registered email id</label>
            <input
                name="email"
                id="email"
                type="email"
                className="main-form__email-input-field"
                value={email}
                placeholder="username@gmail.com"
                onChange={handleEmailInput}
                disabled={showOtpField}
            />
        </>
    )
}

export default DeleteAccountEmailField