import React, { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom";
import { BASE_URL } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"
import "./DeleteAccount.css"
import ReactLoading from "react-loading";
import isEmail from "validator/lib/isEmail";
import DeleteAccountStepperHead from "../../SubComponents/DeleteAccountStepperHead/DeleteAccountStepperHead";
import DeletAccountGetOTP from "../../SubComponents/DeletAccountGetOTP/DeletAccountGetOTP";
import DeleteAccountSubmitOTP from "../../SubComponents/DeleteAccountSubmitOTP/DeleteAccountSubmitOTP";
import LogoApps from "../../../assets/images/LogoApps.png";
import arrow from "../../../assets/images/BxLeftArrowAlt 1.svg"
import checkCircle from "../../../assets/images/BxBxsCheckCircle 1.svg"

const DeleteAccount = (props) => {
    const length = 7
    const [showOtpField, setshowOtpField] = useState(false)
    const [verifyOTP, setVerifyOTP] = useState(false)
    const [email, setEmail] = useState("")
    const [otp, setOtp] = useState(new Array(length).fill(""))
    const [formErrors, setFormErrors] = useState({})
    const [startUpload, setStartUpload] = useState(false)
    const inputRefs = useRef([]);
    let err = {}

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [showOtpField]);

    const handleEmailInput = (e) => {
        setEmail(e.target.value)
        if (e.target.value.length <= 0) {
            setFormErrors({})
        }
    }

    const runValidations = async () => {
        if (email.trim().length === 0) {
            err.email = "Email field cannot be empty"
        } else if (!isEmail(email)) {
            err.email = "Enter valid email"
        }
        if (otp.join("").trim().length === 0) {
            err.otp = "OTP cannot be empty"
        } else if (otp.join("").trim().length < length) {
            err.otp = "Enter full OTP"
        }
    }

    const getOtpFunc = async (e) => {
        e.preventDefault()
        runValidations()
        if (!err.hasOwnProperty('email')) {
            setFormErrors({})
            try {
                const sendOtp = await axios.get(`${BASE_URL}/login/delete_otp?email_id=${email}`)
                if (sendOtp.status === 200) {
                    toast.success("OTP sent for registered Email ID")
                    setshowOtpField(true)
                }
            } catch (err) {
                console.log("err in sending OTP to registered mail", err)
                toast.error("Error in sending OTP, please try again...!")
            }
        } else {
            setFormErrors(err)
        }
    }

    const handleOTPChange = (index, e) => {
        const value = e.target.value
        inputRefs.current[index].style.background = value ? "#C3DDFD" : "#F9FAFB"
        if (isNaN(value)) return

        const newOtpArr = [...otp]
        newOtpArr[index] = value
        setOtp(newOtpArr)

        // Move to next input if current field is filled
        if (value && index < length - 1 &&
            inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
    }

    const handleKeyDown = (index, e) => {
        if (
            e.key === "Backspace" &&
            !otp[index] &&
            index > 0 &&
            inputRefs.current[index - 1]
        ) {
            // Move focus to the previous input field on backspace
            inputRefs.current[index - 1].focus();
            inputRefs.current[index].style.background = "#F9FAFB"
        }
    };

    const handleOTPInputClick = (index) => {
        // ref: setSelectionRange -- Mozilla
        inputRefs.current[index].setSelectionRange(1, 1);

        // optional
        if (index > 0 && !otp[index - 1]) {
            inputRefs.current[otp.indexOf("")].focus();
        }
    };

    const handleCancel = () => {
        setshowOtpField(false)
        setOtp(new Array(length).fill(""))
    }

    const otpSubmitFunc = async (e) => {
        e.preventDefault()
        const data = {
            otp: otp.join(""),
            email_id: email
        };
        runValidations()
        if (Object.keys(err).length === 0) {
            try {
                setStartUpload(true)
                const deleteAccount = await axios.post(`${BASE_URL}/login/delete_account`, data)
                toast.success("Account deleted successfully")
                setTimeout(() => {
                    setStartUpload(false)
                    setVerifyOTP(true)
                    setshowOtpField(false)
                }, 3000)
            } catch (error) {
                console.log("err.response", error.response)
                err.otp = error?.response?.data?.message
                setFormErrors(err)
                setStartUpload(false)
            }
        } else {
            // toast.info("Kinldy check the option to delete the account permanently")
            setFormErrors(err)
        }
    }

    return (
        <div style={{ background: startUpload && "light gray", opacity: startUpload && "0.4" }}>
            {startUpload ? (
                <ReactLoading
                    type="spin"
                    color="#2f80ed"
                    className="startUploadLoadSpin"
                />
            ) : null}
            <div className="logo">
                <Link className="logoBtn" to="/">
                    <img src={LogoApps} alt="Logo-Apppsndevices" />
                </Link>
            </div>
            <div className="delete-account-page">
                <div className="delete-account-page_header">
                    {"Account Delete"}
                </div>
                <div className="delete-account-page__stepper-container">
                    <DeleteAccountStepperHead showOtpField={showOtpField} verifyOTP={verifyOTP} />
                    {!verifyOTP ? (
                        <div className="stepper-container__main">
                            <div className="stepper-container__main-heading" style={{ justifyContent: showOtpField ? "flex-start" : "center" }}>
                                {showOtpField &&
                                    <img
                                        src={arrow}
                                        alt="Back-menu"
                                        style={{ marginLeft: "2%", cursor: "pointer" }}
                                        onClick={() => setshowOtpField(!showOtpField)}
                                    />
                                }
                                <div style={{ marginLeft: showOtpField ? "43%" : 0 }}>
                                    {!showOtpField ? "Account Info" : "Enter OTP"}
                                </div>
                            </div>
                            <div className="stepper-container__main-msg" style={{ color: showOtpField ? "#009951" : "#373737" }}>
                                {!showOtpField ?
                                    "You will receive one time code on your below entered email and add that in next step" :
                                    `Email with otp has been sent to you ${email}`
                                }
                            </div>
                            {!showOtpField ? (
                                <DeletAccountGetOTP
                                    showOtpField={showOtpField}
                                    handleEmailInput={handleEmailInput}
                                    email={email}
                                    getOtpFunc={getOtpFunc}
                                    formErrors={formErrors}
                                />
                            ) : (
                                <DeleteAccountSubmitOTP
                                    showOtpField={showOtpField}
                                    handleEmailInput={handleEmailInput}
                                    email={email}
                                    otpSubmitFunc={otpSubmitFunc}
                                    otp={otp}
                                    inputRefs={inputRefs}
                                    handleOTPChange={handleOTPChange}
                                    handleKeyDown={handleKeyDown}
                                    handleOTPInputClick={handleOTPInputClick}
                                    handleCancel={handleCancel}
                                    formErrors={formErrors}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="stepper-container__main" style={{ justifyContent: "center" }}>
                            <img src={checkCircle} alt="Success-tick" />
                            <div className="stepper-container__main--success-msg">{"Your Account has been delete"}</div>
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default DeleteAccount