import React from "react";
import "../../Screens/DeleteAccount/DeleteAccount.css"

const DeleteAccountStepperHead = (props) => {
    const { showOtpField, verifyOTP } = props
    return (
        <div className="stepper-container__header">
            <div className="stepper-container__header--text" style={{
                color: !showOtpField && !verifyOTP ? "#1C64F2" : "#6B7280"
            }}>{"Account Info"}</div>
            <hr style={{ border: "1px solid", width: "25%" }}></hr>
            <div className="stepper-container__header--text" style={{
                color: showOtpField ? "#1C64F2" : "#6B7280"
            }}>{"Enter OTP"}</div>
            <hr style={{ border: "1px solid", width: "25%" }}></hr>
            <div className="stepper-container__header--text" style={{
                color: verifyOTP ? "#1C64F2" : "#6B7280"
            }}>{"Confirm"}</div>
        </div>
    )
}
export default DeleteAccountStepperHead